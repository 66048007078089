.character-error {
  border-color: #ced4da;
  width: 46px;
  border-radius: 4px;
  padding: 0;
  color: #1d2b4f;
  line-height: 63px;
  font-size: 24px;
  outline: 2px solid #fd0d0d59;
}

.character {
  border-color: #ced4da;
  width: 46px;
  border-radius: 4px;
  padding: 0;
  color: #1d2b4f;
  line-height: 63px;
  font-size: 24px;
}

.character-inactive {
  background-color: white;
}

.container {
  height: 72px;
}
